import React from 'react';

import Content from 'components/shared/Content';
import Heading from 'components/shared/Heading';
import Text from 'components/shared/Text';
import styled from 'styled-components';

const PRIVACY_POLICY_HEADING = 'POLITYKA <br/> PRYWATNOŚCI';
const COOKIES_POLICY_HEADING = 'POLITYKA <br/> COOKIES';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  padding: 32px 0;
  margin-top: 85px;
  max-width: 700px;

  ${({ theme }) => theme.mq.lg} {
    padding: 72px 0;
    margin-top: 100px;
  }

  @media (min-width: 1920px) {
    max-width: 840px;
    padding: 96px 0;
  }
`;

const Header = styled.header`
  h1 {
    margin-bottom: 16px;
  }
  padding-bottom: 24px;
  margin-bottom: 40px;
  border-bottom: 2px solid #d5d5d5;

  ${({ theme }) => theme.mq.lg} {
    margin-bottom: 48px;
  }
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 16px;
`;

const StyledText = styled(Text)``;

const Description = styled(StyledText)`
  font-size: 16px;
  color: #313131;
  text-align: left;
`;

const StyledContent = styled.div`
  padding-left: 2.4rem;
  * {
    color: #313131;
  }

  ol {
    list-style: auto;
  }

  strong {
    display: block;
    margin-bottom: 8px;

    font-size: 2.6rem;
    font-weight: 600;
  }

  li {
    font-size: 1.6rem;
    line-height: 1.4;

    margin-bottom: 16px;
  }

  strong,
  li::marker {
    font-size: 2rem;
  }

  ${({ theme }) => theme.mq.lg} {
    strong {
      margin-bottom: 16px;
    }

    li {
      font-size: 1.6rem;
      line-height: 1.4;
      margin-bottom: 24px;
    }

    strong,
    li::marker {
      font-size: 2.2rem;
    }
  }

  @media (min-width: 1920px) {
    strong {
      margin-bottom: 24px;
    }
  }
`;

export const PrivacyPolicy = ({ description, content, isCookiesPolicy }) => {
  return (
    <Content>
      <Wrapper>
        <Header>
          <StyledHeading
            as="h1"
            dangerouslySetInnerHTML={{
              __html: isCookiesPolicy
                ? COOKIES_POLICY_HEADING
                : PRIVACY_POLICY_HEADING,
            }}
          />
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        </Header>
        <StyledContent dangerouslySetInnerHTML={{ __html: content }} />
      </Wrapper>
    </Content>
  );
};
